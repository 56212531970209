<template>
    <div id="management-user-page">
        <toolbar-back-button-component :redirection="'parameter'" :title="'Gestion des utilisateurs'"/>
        <div class="pa-4">
            <p class="text-align-justify font-12 font-color-light mb-8">
                {{ $t('management_user_page.description') }}
            </p>

            <datatable-filter-component :headers="headers"/>

            <v-row class="ma-0 justify-space-between mb-4">
                <p class="my-auto font-weight-bold font-14">
                    {{ $t('management_user_page.total_users', {total_users: users.length}) }}</p>
                <div>
                    <!--                    <v-btn
                                            class="square-btn-icon"
                                            color="secondary"
                                            outlined
                                        >
                                            <v-icon color="secondary">mdi-download</v-icon>
                                            <p v-if="selected_users.length > 0" class="secondary&#45;&#45;text font-weight-bold mb-0 ml-2">
                                                {{ selected_users.length }} Séléction(s)
                                            </p>
                                        </v-btn>-->

                    <v-btn v-if="selected_users.length > 0" @click="dialog_remove_user = true; multiple_remove = true"
                           class="ml-2 square-btn-icon" color="secondary" outlined
                    >
                        <v-icon color="secondary">mdi-trash-can</v-icon>
                    </v-btn>
                </div>
            </v-row>

            <div>
                <v-data-table
                    v-model="selected_users"
                    :headers="headers"
                    :items="users"
                    :loading="loading"
                    :search="search"
                    checkbox-color="primary"
                    class="mb-16 stripe-date-table select-data-table"
                    disable-sort
                    item-key="email"
                    show-select
                >
                    <template v-slot:item.data-table-select="{ item, isSelected, select }">
                        <span v-if="item.email === $store.getters['user_connected/user_connected'].email"></span>
                        <v-simple-checkbox
                            v-else
                            :value="isSelected"
                            color="primary"
                            @input="select($event)"
                        />
                    </template>
                    <template v-slot:item.last_name="{ item }">
                        <span v-if="item.invitation_state !== null"
                              class="warning--text">{{ $t('management_user_page.in_waiting') }}</span>
                        <span v-else v-html="item.last_name"></span>
                    </template>

                    <template v-slot:item.first_name="{ item }">
                        <span v-if="item.invitation_state !== null"
                              class="warning--text">{{ $t('management_user_page.in_waiting') }}</span>
                        <span v-else v-html="item.first_name"></span>
                    </template>

                    <template v-slot:item.invitation_state="{ item }">
                        <span v-if="item.invitation_state === '0' || item.state !== '2' "
                              class="warning--text">{{ $t('management_user_page.in_waiting') }}</span>
                        <span v-else-if="item.invitation_state === null">{{
                                $t('management_user_page.activate')
                            }}</span>
                        <span v-else-if="item.invitation_state === '-1'"
                              class="error--text">{{ $t('management_user_page.decline') }}</span>
                    </template>

                    <template v-slot:item.date_invitation="{ item }">
                        <span v-if="item.date_invitation !== null">
                            {{ moment(item.date_invitation).format(date_format) }}
                        </span>
                    </template>

                    <template v-if="userHasAccess('BASE',this.role_edit)" v-slot:item.actions="{ item }">
                        <v-icon class="mr-2" @click="selected_user = item; openDialogEditRole()"
                                v-if="item.id_user_invitations !== null && item.email !== $store.getters['user_connected/user_connected'].email"
                        >
                            mdi-pencil
                        </v-icon>

                        <v-icon v-if="item.id_user_invitation !== null" class="mr-2"
                                @click="dialog_resend_email = true; selected_user = item"
                        >
                            mdi-email-send
                        </v-icon>

                        <v-icon @click="dialog_remove_user = true; selected_user = item; multiple_remove = false"
                                v-if="item.email !== $store.getters['user_connected/user_connected'].email && item.is_principal !== '1'"
                                class="mr-2"
                        >
                            mdi-trash-can
                        </v-icon>
                    </template>
                </v-data-table>
            </div>
        </div>

        <v-dialog v-model="dialog_edit_role" max-width="500px">
            <v-form ref="formEditRole">
                <v-card>
                    <v-card-title>
                        <span class="text-uppercase font-weight-bold">
                            {{ $t('management_user_page.modify_role') }}
                        </span>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-select
                            v-model="selected_role"
                            :items="list_role"
                            :menu-props="{ bottom: true, offsetY: true }"
                            :rules="[rules.required]"
                            filled
                            item-text="name"
                            item-value="id_role_client"
                            :label="$t('management_user_page.user_profile')"
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialogEditRole()">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn :disabled="selected_role === null" :loading="loading_edit" @click="editRole()"
                               class="btn-secondary-loader" color="primary" text
                        >
                            <span :class="selected_role !== null ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                                {{ $t('global.update') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog v-model="dialog_resend_email" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="text-uppercase font-weight-bold">
                        {{ $t('management_user_page.send_back_invitation') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <span>
                        {{ $t('management_user_page.would_you_send_invitation_to_user') }}
                        <span class="font-weight-bold">{{ selected_user.email }}</span>&thinsp;?
                    </span>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn
                        text
                        @click="dialog_resend_email = false"
                    >
                        <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn :loading="loading_edit" @click="resendEmail()"
                           class="btn-secondary-loader" color="primary" text
                    >
                        <span class="secondary--text font-weight-bold">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_remove_user" max-width="500px">
            <v-card>
                <v-card-title>
                    <span v-if="multiple_remove" class="text-uppercase font-weight-bold error--text">
                        {{ $t('management_user_page.delete_users_access') }}
                    </span>
                    <span v-else class="text-uppercase font-weight-bold error--text">
                        {{ $t('management_user_page.delete_user_access') }}
                    </span>
                </v-card-title>

                <v-card-text class="pb-0">
                    <div v-if="multiple_remove">
                        <span>
                            {{ $t('management_user_page.wish_you_delete_users_access') }}
                            <br>
                            <span v-for="selected_user in selected_users"
                                  :key="selected_user.email" class="font-weight-bold ml-2"
                            >
                                - {{ selected_user.email }}<br>
                            </span>
                        </span>
                    </div>

                    <div v-else>
                        <span>
                            {{ $t('management_user_page.wish_you_delete_user_access') }}
                            <span class="font-weight-bold">{{ selected_user.email }}</span>&thinsp;?
                        </span>
                    </div>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn text @click="dialog_remove_user = false">
                        <span class="font-weight-bold">{{ $t('global.cancel') }}</span>
                    </v-btn>
                    <v-btn :loading="loading_edit" @click="removeAccessUser()"
                           class="btn-error-loader" color="error" text
                    >
                        <span class="error--text font-weight-bold">{{ $t('global.validate') }}</span>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-btn :class="is_mobile ? 'bottom-left-btn-mobile' : 'bottom-left-btn-desktop'"
               v-if="userHasAccess('BASE',this.role_edit)" @click="dialog_send_email_invitation = true"
               elevation="1" color="secondary" fab
        >
            <v-icon color="primary">mdi-plus</v-icon>
        </v-btn>

        <v-dialog v-model="dialog_send_email_invitation" max-width="500px">
            <v-form ref="formSendEmailInvitation" max-width="500px">
                <v-card>
                    <v-card-title>
                        <span class="text-uppercase font-weight-bold">
                            {{ $t('management_user_page.add_new_user') }}
                        </span>
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-text-field v-model="new_email" :rules="[rules.required, rules.email]"
                                      filled :label="$t('management_user_page.address_mail')"
                        />

                        <v-select
                            v-model="new_role"
                            :items="list_role"
                            :menu-props="{ bottom: true, offsetY: true }"
                            filled
                            item-text="name"
                            item-value="id_role_client"
                            :label="$t('management_user_page.user_profile')"
                        />
                    </v-card-text>

                    <v-card-actions class="justify-end">
                        <v-btn text @click="closeDialogSendEmail()">
                            <span class="font-color-light font-weight-bold">{{ $t('global.cancel') }}</span>
                        </v-btn>
                        <v-btn :disabled="!valid_send_invitation" :loading="loading_edit" @click="sendEmail()"
                               class="btn-secondary-loader" color="primary" text
                        >
                            <span
                                :class="valid_send_invitation ? 'secondary--text font-weight-bold' : 'font-color-light font-weight-bold'">
                                {{ $t('management_user_page.send_invitation') }}
                            </span>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import ToolbarBackButtonComponent from "@/components/Global/ToolbarBackButtonComponent";
import DatatableFilterComponent from "@/components/Global/DatatableFilterComponent";
import ClientRepository from "@/repositories/ClientRepository";
import UserInvitationRepository from "@/repositories/UserInvitationRepository";
import ProfessionalRepository from "@/repositories/ProfessionalRepository";

export default {
    name: "ManagementUserPage",
    components: {DatatableFilterComponent, ToolbarBackButtonComponent},
    created() {
        this.getUserDatatable();
        this.getRole();
    },
    data() {
        return {
            users: [],

            loading: false,
            loading_edit: false,
            selected_user: '',

            dialog_edit_role: false,

            dialog_resend_email: false,

            dialog_remove_user: false,
            multiple_remove: false,

            dialog_send_email_invitation: false,
            new_email: '',
            new_role: null,

            email_pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            rules: {
                required: value => !!value || this.$t('global.field_required'),
                email: value => {
                    const pattern = this.email_pattern
                    return pattern.test(value) || this.$t('management_user_page.email_not_valid')
                },
            },

            list_role: [],
            selected_role: null,

            selected_users: [],
            search: '',
            headers: [
                {text: this.$t('management_user_page.last_name'), value: 'last_name', filterable: true},
                {text: this.$t('management_user_page.first_name'), value: 'first_name', filterable: true},
                {text: this.$t('management_user_page.address_mail'), value: 'email', filterable: true},
                {text: this.$t('management_user_page.phone_number'), value: 'phone_number', filterable: true},
                {text: this.$t('management_user_page.profile'), value: 'role_name', filterable: true},
                {text: this.$t('management_user_page.state'), value: 'invitation_state', filterable: true},
                {text: this.$t('management_user_page.date_add'), value: 'date_invitation', filterable: true},
                {text: 'Action', value: 'actions', filterable: true},
            ],
        }
    },
    computed: {
        valid_send_invitation() {
            return this.email_pattern.test(this.new_email) && this.new_role !== null;
        }
    },
    methods: {
        getUserDatatable() {
            this.loading = true;
            ClientRepository.getUserDatatable(this.$store.getters["user_connected/user_connected"].id_client).then((success) => {
                this.users = success.data.data;
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading = false;
            });
        },

        getRole() {
            ProfessionalRepository.getClientRole(this.$store.getters["user_connected/user_connected"].id_professional).then((success) => {
                this.list_role = success.data.data
            }).catch((error) => {
                this.manageError(error);
            });
        },

        editRole() {
            if (this.selected_user.id_user_invitation !== null) {
                this.editUserInvitationRole();
            } else {
                this.editUserRole();
            }
        },

        editUserInvitationRole() {
            this.loading_edit = true;
            UserInvitationRepository.putRoleInvitation(
                parseInt(this.selected_user.id_user_invitation),
                parseInt(this.selected_role)
            ).then(() => {
                this.showSnackbar('success', this.$t('management_user_page.modify_success'));
                this.getUserDatatable();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.closeDialogEditRole();
                this.loading_edit = false;
            });
        },

        editUserRole() {
            this.loading_edit = true;
            ClientRepository.putEditUserRole(
                parseInt(this.$store.getters["user_connected/user_connected"].id_client),
                parseInt(this.selected_user.id_user),
                parseInt(this.selected_role)
            ).then(() => {
                this.showSnackbar('success', this.$t('management_user_page.modify_success'));
                this.getUserDatatable();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.closeDialogEditRole();
                this.loading_edit = false;
            });
        },

        openDialogEditRole() {
            this.selected_role = this.selected_user.id_role_client;
            this.dialog_edit_role = true;
        },

        closeDialogEditRole() {
            this.dialog_edit_role = false;
            this.$refs.formEditRole.reset();
        },

        sendEmail() {
            this.loading_edit = true;
            UserInvitationRepository.postInviteUser(
                this.$store.getters["user_connected/user_connected"].id_client,
                {
                    email: this.new_email,
                    id_role_client: parseInt(this.new_role)
                }
            ).then(() => {
                this.showSnackbar('success', this.$t('management_user_page.invitation_send'));
                this.getUserDatatable();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.closeDialogSendEmail();
                this.loading_edit = false;
            });
        },

        closeDialogSendEmail() {
            this.dialog_send_email_invitation = false;
            this.$refs.formSendEmailInvitation.reset();
        },

        resendEmail() {
            this.loading_edit = true;
            UserInvitationRepository.postResendInvitationUser(this.selected_user.id_user_invitation).then(() => {
                this.showSnackbar('success', this.$t('management_user_page.invitation_send'))
                this.getUserDatatable();
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.dialog_resend_email = false;
            });
        },

        removeAccessUser() {
            this.loading_edit = true;

            let id_users = [];
            let id_user_invitations = [];

            if (this.multiple_remove) {
                this.selected_users.forEach(user => {
                    if (user.id_user_invitation === null) {
                        id_users.push(parseInt(user.id_user));
                    } else {
                        id_user_invitations.push(parseInt(user.id_user_invitation));
                    }
                });
            } else if (this.selected_user.id_user_invitation === null) {
                id_users.push(parseInt(this.selected_user.id_user));
            } else {
                id_user_invitations.push(parseInt(this.selected_user.id_user_invitation));
            }

            if (id_users.length > 0) {
                ClientRepository.deleteUserAccess(this.$store.getters["user_connected/user_connected"].id_client, {id_users: id_users}).then(() => {
                    this.showSnackbar('success', this.$t('management_user_page.user_no_longer_access'));
                    this.getUserDatatable();
                }).catch((error) => {
                    this.manageError(error);
                }).finally(() => {
                    this.dialog_remove_user = false;
                    this.loading_edit = false;
                });
            }
            if (id_user_invitations > 0) {
                UserInvitationRepository.deleteUserInvitation({id_user_invitations: id_user_invitations}).then(() => {
                    this.showSnackbar('success', this.$t('management_user_page.user_no_longer_access'));
                    this.getUserDatatable();
                }).catch((error) => {
                    this.manageError(error);
                }).finally(() => {
                    this.dialog_remove_user = false;
                    this.loading_edit = false;
                });
            }
        },
    }
}
</script>